import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import Player from '@vimeo/player'
import { CircularProgress } from '@material-ui/core'
import { styleVariables } from '../../assets/styles/globalStyles'

const PlayerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  /* z-index: 10; */
  pointer-events: none;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    height: 250px;
  }

  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .played {
    display: none;
  }
`

const VideoBackgroundParalax = ({ videoId }) => {
  const [played, setPlayed] = useState(false)
  const playerRef = useRef(null)
  useEffect(() => {
    const player = new Player(playerRef.current)

    player.on('play', () => {
      setPlayed(true)
    })
  }, [])
  return (
    <>
      <PlayerWrapper>
        <CircularProgress
          style={{ color: styleVariables.primary }}
          className={played ? 'played' : ''}
        />
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&loop=1&byline=0&title=0&quality=720p`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          ref={playerRef}
        ></iframe>
      </PlayerWrapper>
    </>
  )
}

export default VideoBackgroundParalax
