import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import ButtonLink from '../ButtonLink/ButtonLink'
import Img from 'gatsby-image/withIEPolyfill'
import { useStaticQuery, graphql } from 'gatsby'

const StyledPaper = styled(Paper)`
  display: flex;
  padding: 2rem 0 2rem 0;
  background-color: rgba(0, 0, 0, 0.5);
  @media only screen and (min-width: 768px) {
    height: 400px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
  }

  .imageWrapper {
    /* flex: 1; */
    position: relative;
    width: 800px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      height: 230px;
      overflow: hidden;

      img {
        object-fit: cover !important;
      }
    }
  }

  .contentWrapper {
    padding: 1rem;
    height: 570px;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h2 {
      display: block;
      height: 70px;
      color: var(--primaryDark);

      @media only screen and (max-width: 768px) {
        height: unset;
      }
    }
  }
`

export default props => {
  const data = useStaticQuery(
    graphql`
      {
        niepodlegla01: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla01/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla02: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla02/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla03: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla03/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla04: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla04/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla05: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla05/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla06: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla06/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla07: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla07/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla08: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla08/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla09: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla09/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla10: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla10/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla11: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla11/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla12: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla12/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla13: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla13/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla14: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla14/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla15: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla15/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla16: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla16/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        niepodlegla17: file(
          relativePath: { regex: "/niepodlegla/" }
          name: { regex: "/niepodlegla17/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const items = [
    {
      name: 'DOBRE - Popiersie Marszałka Piłsudskiego',
      description: `W centrum Dobrego znajduje się odsłonięty 5 maja 2002 roku pomnik zawierający popiersie Marszałka Józefa Piłsudskiego. Można jednak powiedzieć, że historia tego pomnika...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla01.childImageSharp.fluid,
    },
    {
      name: 'JAKUBÓW - Obelisk z 1922 roku',
      description: `Obelisk z 1922 roku upamiętniający bohaterów poległych w walkach o niepodległość Ojczyzny w latach 1919-1920 został odsłonięty w 1922 roku, a więc w czwartą rocznicę odzyskania Niepodległości...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla02.childImageSharp.fluid,
    },
    {
      name: 'KAŁUSZYN - Pomnik „Bojownikom o Wolność”',
      description: `W dwudziestą rocznicę odzyskania Niepodległości w Kałuszynie wzniesiono pomnik poświęcony Bojownikom o Niepodległość. Autorem projektu był burmistrz...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla03.childImageSharp.fluid,
    },
    {
      name: 'JĘDRZEJÓW - Głaz z Tablicą Pamiątkową',
      description: `Głaz z Tablicą Pamiątkową Jędrzejów Nowy przez wiele wieków był posiadłością rodu ziemiańskiego Jędrzejowskich. Ostatnim z właścicieli majątku był szlachcic...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla04.childImageSharp.fluid,
    },
    {
      name: 'CEGŁÓW - Pomnik Niepodległości',
      description: `Pomnik Niepodległości Na historycznym Rynku, obecnym placu Anny Jagiellonki, usytuowano Pomnik upamiętniający dziesiątą rocznicę odzyskania Niepodległości. Jego...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla05.childImageSharp.fluid,
    },
    {
      name: 'MROZY - Pomnik ku czci Bohaterów Gminy Kuflew',
      description: `Pomnik Ku Czci Bohaterów Gminy Kuflew Po odzyskaniu przez Polskę Niepodległości w 1918 roku wieś Mrozy administracyjnie należała do gminy Kuflew, zaś siedzibą władz...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla06.childImageSharp.fluid,
    },
    {
      name: 'KICZKI - Głaz Pamiątkowy',
      description: `Głaz Pamiątkowy W 1928 roku staraniem wiejskiej społeczności Kiczek, przy miejscowym Kościele parafialnym św. Anny, postawiono pamiątkowy głaz upamiętniający dziesiątą...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla07.childImageSharp.fluid,
    },
    {
      name: 'LATOWICZ - Pomnik ku czci Bojowników o Niepodległość',
      description: `Pomnik ku czci Bojowników o Niepodległość W zachodniej części latowickiego rynku, dla upamiętnienia dziesiątej rocznicy odzyskania Niepodległości ufundowano pomnik...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla08.childImageSharp.fluid,
    },
    {
      name: 'SIENNICA - Pomnik w Hołdzie wolnej Szkole Polskiej',
      description: `Pomnik w hołdzie wolnej Szkole Polskiej Kamień został ustawiony w Siennicy w 1928 roku, w dziesięciolecie odzyskania Niepodległości. Prosty pomnik nawiązuj do tradycji...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla09.childImageSharp.fluid,
    },
    {
      name:
        'MIŃSK MAZOWIECKI - Pomnik Niepodległości na Starym Rynku oraz Obraz Matki Boskiej Hallerowskiej w Kościele pw. NNMP.',
      description: `Pomnik Niepodległości na Starym Rynku Miński pomnik został wzniesiony dla uczczenia osiemdziesiątej rocznicy Cudu nad Wisłą. W 1997 roku powstał Społeczny...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla10.childImageSharp.fluid,
    },
    {
      name: 'MIŃSK MAZOWIECKI Głaz przy liceum im. Polskiej Macierzy Szkolnej',
      description: `Głaz przy liceum im. Polskiej Macierzy Szkolnej Przy wejściu do głównego budynku liceum im. Polskiej Macierzy Szkolnej w Mińsku Mazowieckim znajduje się głaz...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla11.childImageSharp.fluid,
    },
    {
      name: 'ŁADZYŃ - Pomnik z 1928 roku',
      description: `Pomnik z 1928 roku W dziesiątą rocznicę wskrzeszenia Państwa Polskiego ufundowano w Ładzyniu (ówczesnej miejscowości gminnej) pomnik w postaci kamiennego głazu...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla12.childImageSharp.fluid,
    },
    {
      name: 'OKUNIEW - Mogiła Żołnierska z 1920 roku',
      description: `Mogiła Żołnierska 1920 roku Na okuniewskim cmentarzu parafialnym znajduje się zbiorowa mogiła kilku żołnierzy wojny polsko-bolszewickiej. W połowie sierpnia 1920...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla13.childImageSharp.fluid,
    },
    {
      name: 'SULEJÓWEK - Muzeum Józefa Piłsudskiego',
      description: `Muzeum Józefa Piłsudskiego Ideą tego muzeum jest przedstawienie postaci Józefa Piłsudskiego na szerokim tle historycznym, kulturowym i społecznym...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla14.childImageSharp.fluid,
    },
    {
      name: 'SULEJÓWEK - Kopiec i Pomnik',
      description: `Kopiec i Pomik Nieopodal willi „Milusin”, u zbiegu ulic Piłsudskiego i Paderewskiego w Sulejówku znajduje się Skwer Niepodległości. Początkowo, czyli od 1988 roku...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla15.childImageSharp.fluid,
    },
    {
      name: 'OKUNIEW-ZABRANIEC - Krzyż Pamiątkowy między Okuniewem a Zabrańcem',
      description: `Krzyż Pamiątkowy Między Okuniewem a Zabrańcem W 1929 roku państwo Jan i Ewa Strzelec na wydmie znajdującej się na północ od Okuniewa, przy drodze...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla16.childImageSharp.fluid,
    },
    {
      name:
        'STANISŁAWÓW - Pomnik mieszkańców Stanisławowa poległych w latach 1914-1920',
      description: `Pomnik mieszkańców Stanisławowa poległych w latach 1914-1920 Z inicjatywy pochodzącego ze Stanisławowa kapitana Józefa Boruckiego, na tamtejszym Rynku...`,
      link: '/niepodlegla',
      fluid: data.niepodlegla17.childImageSharp.fluid,
    },
  ]

  let showNavButtons = true

  const isBrowser = typeof window !== `undefined`
  if (isBrowser) {
    showNavButtons = window.innerWidth > 768
  }

  return (
    <Carousel
      navButtonsAlwaysVisible={showNavButtons}
      animation="slide"
      timeout={400}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  )
}

const Item = ({ item }) => {
  return (
    <StyledPaper>
      <div className={'imageWrapper'}>
        <Img fluid={item.fluid} />
      </div>

      <div className={'contentWrapper'}>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
        <ButtonLink linkTo={item.link} style={{ margin: '0 auto !important' }}>
          PRZEJDŹ
        </ButtonLink>
      </div>
    </StyledPaper>
  )
}
