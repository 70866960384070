import React from 'react'
import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'
import ReactAudioPlayer from 'react-audio-player'
import ButtonLinkAsset from '../ButtonLink/ButtonLinkAsset'

const ContentGrid = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const CardWrapper = styled.div`
  height: 100%;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardItemTitle = styled.h3`
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--primaryDark);
`

export default props => {
  const items = [
    {
      Items: [
        {
          Type: 'A',
          Name: 'Dobre - dawne miasteczko w ziemi liwskiej',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Dobre_dawne_miasteczko_w_ziemi_liwskiej_RMM_r1995_1996_t3_s211_221.mp3',
        },
        {
          Type: 'P',
          Name: 'Klasztor zakonu OO. Franiciszkanów-Reformatów w Siennicy',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/zakon-franciszkanow-1545993780.pdf',
        },
        {
          Type: 'A',
          Name: 'Sanatorium Rudka w latach 1908-1950',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Mrozy+Sanatoriu+Rudka+S%C5%82awomir+Kuligowski.mp3',
        },
      ],
    },
    {
      Items: [
        {
          Type: 'P',
          Name: 'Cegłów dawniej i dziś',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/ceglow/Album_Ceglow_dawniej_i_dzis_04.pdf',
        },
        {
          Type: 'A',
          Name: 'K. Sr. Nowy Kościół w Kałuszynie (1896r)',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/K.+Sr.+Nowy+Kos%CC%81cio%CC%81%C5%82+w+Ka%C5%82uszynie+(1896r).mp3',
        },
        {
          Type: 'P',
          Name: 'Karczmy wzdłuż traktu brzeskiego: ich znaczenie i funkcje',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/DebeWielkieRoczniktom262018KarolinaWandaGankoKarczmywzdluztraktubrzeskiegoichznaczenieifunkcjeszkic.pdf',
        },
      ],
    },
    {
      Items: [
        {
          Type: 'A',
          Name:
            'Eugeniusz Religa (1909-1995) - międzywojenny nauczyciel i żołnierz',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Latowicz+e_religa_latowicz.mp3',
        },
        {
          Type: 'P',
          Name:
            'Piłsudczycy bez Piłsudskiego. Obóz Zjednoczenia Narodowego w powiecie mińskim 1937-1939',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/MinskMazowieckiRoczniktom262018DamianSitkiewiczPilsudczycybezPilsudskiego..pdf',
        },
        {
          Type: 'A',
          Name: 'Moja Szkoła w Sulejówku',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Sulej%C3%B3wek+Ewa+Kara%C5%9B+Moja+Szko%C5%82a+w+Sulej%C3%B3wku.mp3',
        },
      ],
    },
    {
      Items: [
        {
          Type: 'P',
          Name: 'SIENNICA Gmina i miejscowość 100 lat temu i dziś',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/Siennica+Dawniej+i+Dzisiaj.pdf',
        },
        {
          Type: 'A',
          Name: 'Siennica i seminarium (1927)',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/P.+Szela%CC%A8g+Siennica+i+seminarium++(1927).mp3',
        },
        {
          Type: 'P',
          Name: 'Opowieść o pamiętnym polskim Wrześniu z mariawitami w tle',
          Link:
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/JakubowRoczniktom262018KrzysztofMazurOpowiescopamietnympolskimWrzesniuzmariawitamiwtle%E2%80%A6.pdf',
        },
      ],
    },
  ]

  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      indicators={true}
      navButtonsAlwaysVisible={true}
      navButtonsAlwaysInvisible={true}
    >
      {items.map((item, index) => {
        return <Banner item={item} key={index} />
      })}
    </Carousel>
  )
}

const Banner = props => {
  const totalItems = props.length ? props.length : 3
  const mediaLength = totalItems

  let items = []

  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i]

    const media = (
      <CardWrapper key={item.Name}>
        <CardItemTitle>{item.Name}</CardItemTitle>
        {item.Type == 'A' ? (
          <ReactAudioPlayer src={item.Link} controls />
        ) : (
          <ButtonLinkAsset linkTo={item.Link}>Otwórz</ButtonLinkAsset>
        )}
      </CardWrapper>
    )

    items.push(media)
  }

  return <ContentGrid>{items}</ContentGrid>
}
