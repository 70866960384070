import styled from 'styled-components'
import React from 'react'
import Vimeo from '@u-wave/react-vimeo'

const videoMonuments = ['483070697', '896591539', '896583059', '896567775']

const TopVideoMonumentsWrapper = styled.section`
  margin-bottom: 30px;
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export default function TopVideoMonuments({ ...props }) {
  return (
    <TopVideoMonumentsWrapper {...props}>
      <GridWrapper>
        {videoMonuments.map(el => (
          <Vimeo video={el} responsive />
        ))}
      </GridWrapper>
    </TopVideoMonumentsWrapper>
  )
}
