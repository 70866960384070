import React from 'react'
import styled from 'styled-components'
import { Container } from '@material-ui/core'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import MapComponent from '../MapComponent/MapComponent'
import TopMonumentsNiepodlegla from '../TopMonuments/TopMonumentsNiepodlegla'
import { Header1, Header2 } from '../Headers/Headers'
import VideoBackgroundParalax from '../VideoBackground/VideoBackgroundParalax'
import BackgroundSection from '../BackgroundSection/BackgroundSection'
import BackgroundSectionMobile from '../BackgroundSection/BackgroundSectionMobile'

const HomepageGridWrapper = styled.section`
  padding: 40px 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  &:not(:first-child) {
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }
`

const Grid1 = styled(Grid)`
  grid-template-columns: 1fr 2fr;
  position: relative;
`

const Grid2 = styled(Grid)`
  grid-template-columns: 1fr 1fr;
`

const Grid3 = styled(Grid)`
  grid-template-columns: 2fr 1fr;
`

const ElementWrapper = styled.div`
  background-color: gray;
  height: 100%;
  position: relative;
`

const ImageWrapper = styled.aside`
  height: 300px;
  width: 100%;
  overflow: hidden !important;

  img {
    object-position: center center;
  }
`

const Caption = styled.div`
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 28, 93, 0.719);
  color: white;
  padding: 9px;
  bottom: 20px;
  left: -20px;

  @media only screen and (max-width: 768px) {
    bottom: 15px;
    left: 15px;
    right: 15px;
  }
`

export default props => {
  const data = useStaticQuery(
    graphql`
      {
        item1: file(
          relativePath: { regex: "/minsk-mazowiecki/cmentarz-zydowski/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item2: file(
          relativePath: { regex: "/ceglow/kiczki-kosciol-i-muzeum-parafialne/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item3: file(
          relativePath: { regex: "/siennica/muzeum-szkolne/" }
          name: { regex: "/IMG_8533/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item4: file(
          relativePath: { regex: "/minsk-mazowiecki/mb-hallerowska-nnmp/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item5: file(
          relativePath: { regex: "/dobre/kosciol/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item6: file(
          relativePath: { regex: "/minsk-mazowiecki/23blot/" }
          name: { regex: "/IMG_1242/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item7: file(
          relativePath: { regex: "/minsk-mazowiecki/muzeum-wsi-stojadla/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item8: file(
          relativePath: { regex: "/mrozy/kuflew-kosciol/" }
          name: { regex: "/IMG_0874/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item9: file(
          relativePath: { regex: "/stanislawow/dawna-karczma/" }
          name: { regex: "/IMG_8017/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item10: file(
          relativePath: { regex: "/ceglow/kosciol-w-ceglowie/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item11: file(
          relativePath: { regex: "/dobre/muzeum-laszczki/" }
          name: { regex: "/okladkaMuzeum/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item12: file(
          relativePath: {
            regex: "/siennica/cmentarz-przy-kosciele-i-katakumby/"
          }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item13: file(
          relativePath: { regex: "/minsk-mazowiecki/mzm7pul/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item14: file(
          relativePath: { regex: "/kaluszyn/zloty-ulan/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item15: file(
          relativePath: { regex: "/minsk-mazowiecki/cmentarz-parafialny/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item16: file(
          relativePath: { regex: "/minsk-mazowiecki/bot-izba-pamieci/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        item17: file(
          relativePath: { regex: "/siennica/cmentarz-parafialny/" }
          name: { regex: "/featured/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        oldmmz: file(
          relativePath: { regex: "/other/" }
          name: { regex: "/oldmmz/" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        blot: file(
          relativePath: { regex: "/minsk-mazowiecki/23blot/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
      <HomepageGridWrapper {...props}>
        <Container>
          <Grid3>
            <Link to="/gminy/minsk-mazowiecki/cmentarz-zydowski-w-minsku-mazowieckim/">
              <ElementWrapper>
                <Caption>Cmentarz żydowski w Mińsku Mazowieckim</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item1.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/ceglow/kiczki-kosciol-i-muzeum-parafialne/">
              <ElementWrapper>
                <Caption>Kościół i muzeum parafialne w Kiczkach</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item2.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid3>
          <Grid>
            <Link to="/gminy/siennica/muzeum-szkolne-w-siennicy/">
              <ElementWrapper>
                <Caption>Muzeum szkolne w Siennicy</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item3.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid>
          <Grid2>
            <Link to="/gminy/minsk-mazowiecki/obraz-matki-bozej-hallerowskiej/">
              <ElementWrapper>
                <Caption>Obraz Matki Bożej Hallerowskiej</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item4.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/dobre/kosciol/">
              <ElementWrapper>
                <Caption>Zabytkowy kościół w Dobrem</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item5.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid2>
        </Container>
      </HomepageGridWrapper>
      <VideoBackgroundParalax videoId={903344535} />
      <HomepageGridWrapper>
        <Container>
          <MapComponent />
        </Container>
      </HomepageGridWrapper>
      <BackgroundSection
        height={40}
        imageData={data.oldmmz.childImageSharp.fluid}
      />
      <BackgroundSectionMobile
        height={40}
        imageData={data.oldmmz.childImageSharp.fluid}
      />
      <HomepageGridWrapper>
        <Container>
          <Grid>
            <Link to="/gminy/minsk-mazowiecki/23-baza-lotnictwa-taktycznego-im-pplk-pil-jana-zumbacha/">
              <ElementWrapper>
                <Caption>23. Baza Lotnictwa Taktycznego</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item6.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid>
          <Grid1>
            <Link to="/gminy/minsk-mazowiecki/muzeum-wsi-stojadla">
              <ElementWrapper>
                <Caption>Muzeum wsi Stojadła</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item7.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/mrozy/kosciol-kuflew/">
              <ElementWrapper>
                <Caption>Kościół w Kuflewie</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item8.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid1>
          <Grid>
            <Link to="/gminy/ceglow/kosciol-rzymsko-katolicki-w-ceglowie/">
              <ElementWrapper>
                <Caption>Ołtarz mistrza Lazarusa i kościół w Cegłowie</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item10.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid>
          <Grid2>
            <Link to="/gminy/stanislawow/dawna-karczma-w-stanislawowie/">
              <ElementWrapper>
                <Caption>Dawna karczma na Wielkim Gościńcu Litewskim</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item9.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/dobre/muzeum-konstantego-laszczki/">
              <ElementWrapper>
                <Caption>Muzeum Konstantego Laszczki w Dobrem</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item11.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid2>
        </Container>
      </HomepageGridWrapper>
      <VideoBackgroundParalax videoId={903344691} />
      <HomepageGridWrapper>
        <Container>
          <div style={{ padding: '30px 0' }}>
            <Header2>
              SZLAK TEMATYCZNY "NIEPODLEGŁA" - MIEJSCA PAMIĘCI W POWIECIE
              MIŃSKIM
            </Header2>
            <TopMonumentsNiepodlegla />
          </div>
        </Container>
      </HomepageGridWrapper>
      <BackgroundSection
        height={40}
        imageData={data.blot.childImageSharp.fluid}
      />
      <BackgroundSectionMobile
        height={40}
        imageData={data.blot.childImageSharp.fluid}
      />
      <HomepageGridWrapper>
        <Container>
          <Grid>
            <Link to="/gminy/siennica/cmentarz-przy-kosciele-i-katakumby/">
              <ElementWrapper>
                <Caption>Katakumby poklasztornego kościoła w Siennicy</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item12.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid>
          <Grid3>
            <Link to="/gminy/minsk-mazowiecki/muzeum-ziemi-minskiej-7-pulk-ulanow-lubelskich/">
              <ElementWrapper>
                <Caption>Muzeum Ziemi Mińskiej – oddział 7 PUL</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item13.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/kaluszyn/zloty-ulan-w-kaluszynie">
              <ElementWrapper>
                <Caption>Złoty Ułan w Kałuszynie</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item14.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid3>
          <Grid>
            <Link to="/gminy/minsk-mazowiecki/cmentarz-parafialny-w-minsku-mazowieckim/">
              <ElementWrapper>
                <Caption>Zabytkowe nagrobki w Mińsku Mazowieckim</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item15.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid>
          <Grid2>
            <Link to="/gminy/minsk-mazowiecki/izba-pamieci-bot/">
              <ElementWrapper>
                <Caption>Izba pamięci Brygady Obrony Terytorialnej</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item16.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
            <Link to="/gminy/siennica/cmentarz-parafialny-w-siennicy/">
              <ElementWrapper>
                <Caption>Zabytkowe nagrobki na siennickim cmentarzu</Caption>
                <ImageWrapper>
                  <Img
                    fluid={data.item17.childImageSharp.fluid}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              </ElementWrapper>
            </Link>
          </Grid2>
        </Container>
      </HomepageGridWrapper>
    </>
  )
}

// export default function HomepageGrid({ ...props }) {

// }
