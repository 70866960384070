import React from 'react'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  height: ${({ height }) => height}vh;
  background-repeat: no-repeat;
  distplay: block;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const BackgroundSectionMobile = ({ className, height, imageData }) => {
  return (
    <StyledBackgroundSection
      Tag="aside"
      className={className}
      height={height}
      fluid={imageData}
      backgroundColor={`#040e18`}
    />
  )
}

export default BackgroundSectionMobile
