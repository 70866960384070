import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import ButtonLink from '../ButtonLink/ButtonLink'
import Img from 'gatsby-image/withIEPolyfill'
import { useStaticQuery, graphql } from 'gatsby'

const StyledPaper = styled(Paper)`
  display: flex;
  padding: 2rem 0 2rem 0;
  background-color: rgba(0, 0, 0, 0.5);
  @media only screen and (min-width: 768px) {
    height: 400px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
  }

  .imageWrapper {
    /* flex: 1; */
    position: relative;
    width: 1240px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      height: 230px;
      overflow: hidden;

      img {
        object-fit: cover !important;
      }
    }
  }

  .contentWrapper {
    padding: 1rem;
    height: 570px;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h2 {
      display: block;
      height: 70px;
      color: var(--primaryDark);

      @media only screen and (max-width: 768px) {
        height: unset;
      }
    }
  }
`

export default props => {
  const data = useStaticQuery(
    graphql`
      {
        katakumby: file(
          relativePath: {
            regex: "/siennica/cmentarz-przy-kosciele-i-katakumby/"
          }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        laszczka: file(
          relativePath: { regex: "/universal/" }
          name: { regex: "/okladkasliderlaszczka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: ATTENTION
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        muzeumSzkolneSiennica: file(
          relativePath: { regex: "/siennica/muzeum-szkolne/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        muzeumStojadla: file(
          relativePath: { regex: "/minsk-mazowiecki/muzeum-wsi-stojadla/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: ATTENTION
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        blot: file(
          relativePath: { regex: "/universal/" }
          name: { regex: "/okladkaslider23blot/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: ATTENTION
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        cmentarzParafialnyMMZ: file(
          relativePath: { regex: "/minsk-mazowiecki/cmentarz-parafialny/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        kiczkiKosciolMuzeum: file(
          relativePath: { regex: "/ceglow/kiczki-kosciol-i-muzeum-parafialne/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        ceglowKosciol: file(
          relativePath: { regex: "/ceglow/kosciol-w-ceglowie/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        mzmPUL: file(
          relativePath: { regex: "/minsk-mazowiecki/mzm7pul/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        tpmmZbiory: file(
          relativePath: { regex: "/minsk-mazowiecki/zbiorytpmm/" }
          name: { regex: "/okladka/" }
        ) {
          childImageSharp {
            fluid(
              maxHeight: 340
              maxWidth: 500
              quality: 80
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const items = [
    {
      name: 'Cmentarz przy kościele i katakumby w Siennicy',
      description: `Wysypane żółtym piaskiem korytarze podziemi siennickiego kościoła to miejsce ostatniego spoczynku pracujących tam przed wiekami reformatów. Mikroklimat panujący w podziemiach sprawił, że zwłoki uległy samoistnej mumifikacji. Zachowały się resztki habitów i szaty zakonne.`,
      link: '/gminy/siennica/cmentarz-przy-kosciele-i-katakumby',
      fluid: data.katakumby.childImageSharp.fluid,
    },
    {
      name: 'Muzeum Konstantego Laszczki w Dobrem',
      description: `Urodzony w poddobrzańskim Makówcu Dużym artysta rzeźbiarz, malarz i grafik posiada swoje muzeum już od lat siedemdziesiątych. Konstanty Laszczka, bo o tym byłym rektorze krakowskiej Akademii Sztuk Pięknych mowa, już za swojego życia przekazał kilka prac rzeźbiarskich i medalierskich, które po latach stały się bazą ekspozycji.`,
      link: '/gminy/dobre/muzeum-konstantego-laszczki',
      fluid: data.laszczka.childImageSharp.fluid,
    },
    {
      name: 'Muzeum Szkolne w Siennicy',
      description: `Stworzona przez sienniczan szkolna izba pamiątek przez lata rozrastała się do dzisiejszych rozmiarów. W pierwszej z sal poznajemy historię siennickich szkół pedagogicznych, w drugiej zebrano pamiątki związane z życiem codziennym wsi mazowieckiej, a w sali trzeciej ukazano ofiarność Sienniczan w obronie swojej Ojczyzny.`,
      link: '/gminy/siennica/muzeum-szkolne-w-siennicy',
      fluid: data.muzeumSzkolneSiennica.childImageSharp.fluid,
    },
    {
      name: 'Muzeum wsi Stojadła',
      description: `Zaprezentowane przez Zbigniewa Drewicza fotografie, makiety, obrazy i rzeźby świadczą o bogatej w wydarzenia historii miejscowości. W tym etnograficznym skarbcu zgromadzono ponadto m.in. liczne figurki przedstawiające mieszkańców ubranych w stroje ludowe oraz modele charakterystycznych mińskich budowli.`,
      link: '/gminy/minsk-mazowiecki/muzeum-wsi-stojadla',
      fluid: data.muzeumStojadla.childImageSharp.fluid,
    },
    {
      name: 'Sala tradycji w 23. Bazie Lotnictwa Taktycznego',
      description: `Elementy uzbrojenia samolotów bojowych, pułkowy sztandar i księgi pamiątkowe, naścienne wizerunki orderu Virtuti Militari i myśliwca MIG 29 oraz godła jednostek lotniczych stacjonujących pod Mińskiem Mazowieckim to tylko niektóre z eksponatów zgromadzonych przez lata w sali tradycji mińskiej bazy lotniczej.`,
      link:
        '/gminy/minsk-mazowiecki/23-baza-lotnictwa-taktycznego-im-pplk-pil-jana-zumbacha',
      fluid: data.blot.childImageSharp.fluid,
    },
    {
      name: 'Zabytkowe nagrobki na mińskim cmentarzu ',
      description: `Zaangażowanie minszczan pozwoliło do 2020 roku uratować od zniszczenia około trzydzieści nagrobków posiadających cechy zabytkowości. Wśród dziewiętnastowiecznych mogił należy wspomnieć o miejscu pochówku powstańców styczniowych, jak też duże wrażenie na osobach odwiedzających cmentarz robią pomniki nagrobne kobiet z tego okresu.`,
      link: '/gminy/minsk-mazowiecki/cmentarz-parafialny-w-minsku-mazowieckim/',
      fluid: data.cmentarzParafialnyMMZ.childImageSharp.fluid,
    },
    {
      name: 'Kościół i muzeum parafialne w Kiczkach',
      description: `Świątynia z 1751 roku zbudowana na niewielkim wzniesieniu i otoczona kamiennym murem robi duże wrażenie. Przed kościołem, w drewnianym budynku dawnej „organistówki”, jako dowód szacunku miejscowej ludności wobec przeszłości i zabytków, mieści się parafialne muzeum etnograficzne.`,
      link: '/gminy/ceglow/kiczki-kosciol-i-muzeum-parafialne',
      fluid: data.kiczkiKosciolMuzeum.childImageSharp.fluid,
    },
    {
      name: 'Kościół z Ołtarzem Lazarusa w Cegłowie',
      description: `Późnogotycki kościół w Cegłowie został wzniesiony w połowie XVI wieku. 21 grudnia 1629 roku biskup poznański Maciej Łubieński dokonał konsekracji kościoła i od tego czasu w świątyni znajduje się tryptyk ołtarzowy zwany „Tryptykiem Cegłowskim”. Wykonany został przez ucznia Wita Stwosza, czyli mistrza Lazarusa z Warszawy.`,
      link: '/gminy/ceglow/kosciol-rzymsko-katolicki-w-ceglowie',
      fluid: data.ceglowKosciol.childImageSharp.fluid,
    },
    {
      name: 'Zbiory muzealne dotyczące 7 Pułku Ułanów Lubelskich',
      description: `W mińskiej willi Hubertów od 2001 roku można oglądać zbiory prezentujące historię ułańskiej jednostki, które podzielono na pięć sal wystawowych. Odwiedzający mogą ponadto poznać dzieje jednej ze współczesnych jednostek, którym poświęciono salę pamięci 2 Mińskomazowieckiej Brygady Obrony Terytorialnej im. gen. Franciszka Kleeberga.`,
      link:
        '/gminy/minsk-mazowiecki/muzeum-ziemi-minskiej-7-pulk-ulanow-lubelskich/',
      fluid: data.mzmPUL.childImageSharp.fluid,
    },
    {
      name: 'Unikalne zbiory fotograficzne TPMM',
      description: `Doskonałą sposobnością do fotograficznej podróży w czasie jest zagłębienie się w bazę materiałów zdjęciowych udostępnionych przez Towarzystwo Przyjaciół Mińska Mazowieckiego. Zbiory posiadane przez tę organizację pozarządową stanowią najszerszy katalog regionalistycznych pamiątek w tej części wschodniego Mazowsza.`,
      link:
        '/gminy/minsk-mazowiecki/zbiory-towarzystwa-przyjaciol-minska-mazowieckiego',
      fluid: data.tpmmZbiory.childImageSharp.fluid,
    },
  ]

  let showNavButtons = true

  const isBrowser = typeof window !== `undefined`
  if (isBrowser) {
    showNavButtons = window.innerWidth > 768
  }

  return (
    <Carousel
      navButtonsAlwaysVisible={showNavButtons}
      animation="slide"
      timeout={400}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  )
}

const Item = ({ item }) => {
  return (
    <StyledPaper>
      <div className={'imageWrapper'}>
        <Img fluid={item.fluid} />
      </div>

      <div className={'contentWrapper'}>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
        <ButtonLink linkTo={item.link} style={{ margin: '0 auto !important' }}>
          PRZEJDŹ
        </ButtonLink>
      </div>
    </StyledPaper>
  )
}
