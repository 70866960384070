import { Container } from '@material-ui/core'
import React from 'react'
import { graphql } from 'gatsby'
import BackgroundSection from '../components/BackgroundSection/BackgroundSection'
import BackgroundSectionMobile from '../components/BackgroundSection/BackgroundSectionMobile'
import { Header1, Header2 } from '../components/Headers/Headers'
import SEO from '../components/seo'
import TopMultimediaMonuments from '../components/TopMultimediaMonuments/TopMultimediaMonuments'
import TopMonuments from '../components/TopMonuments/TopMonuments'
import VideoBackground from '../components/VideoBackground/VideoBackground'
import { ReactComponent as Famm } from '../assets/images/fammtekst.svg'
import Img from 'gatsby-image'
import styled from 'styled-components'
// import TopMonumentsNiepodlegla from '../components/TopMonuments/TopMonumentsNiepodlegla'
import HomepageGrid from '../components/HomepageGrid/HomepageGrid'
import Anchor from '../components/Anchor/Anchor'
import ButtonLink from '../components/ButtonLink/ButtonLink'
import TopVideoMonuments from '../components/TopVideoMonuments/TopVideoMonuments'

const LogoContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    /* max-width: 200px; */
    height: 200px;
    max-height: 140px;
    max-width: 350px;
    width: 100%;
    align-self: center !important;
    justify-self: center !important;
    filter: grayscale(100%);

    img {
      object-fit: contain !important;
      max-height: 100%;
    }

    @media only screen and (max-width: 768px) {
      width: auto;
      height: auto;
      max-height: 120px;
      filter: unset;
    }

    &:hover {
      transition: all 0.3s ease;
      filter: grayscale(0);
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Wirtualna Ziemia Mińska"
        image={data.seoimg.childImageSharp.fluid.src}
      />
      <VideoBackground videoId={903344484} />
      <main style={{ backgroundColor: '#fff', paddingTop: 30 }}>
        <Container style={{ paddingBottom: 60 }}>
          {/* <Header2>
            SZLAK TEMATYCZNY "NIEPODLEGŁA" - MIEJSCA PAMIĘCI W POWIECIE MIŃSKIM
          </Header2> */}
          {/* <TopMonumentsNiepodlegla /> */}
        </Container>
        <Container style={{ paddingBottom: 60 }}>
          <Header2>NAJCZĘŚCIEJ ODWIEDZANE</Header2>
          <TopMonuments />
        </Container>
        <BackgroundSection
          height={40}
          imageData={data.trzeciaparalaksa.childImageSharp.fluid}
        />
        <BackgroundSectionMobile
          height={40}
          imageData={data.trzeciaparalaksa.childImageSharp.fluid}
        />
        <HomepageGrid />
        <BackgroundSection
          height={40}
          imageData={data.kosciol.childImageSharp.fluid}
        />
        <BackgroundSectionMobile
          height={40}
          imageData={data.kosciol.childImageSharp.fluid}
        />
        <Container style={{ paddingTop: 30 }}>
          <Anchor id="audiobooki" />
          <Header2>NAJPOPULARNIEJSZE AUDIO I E-BOOKI</Header2>
          <TopMultimediaMonuments />
          <br></br>
          <ButtonLink center linkTo="/audiobooki/">
            Zobacz więcej audiobooków
          </ButtonLink>
          <br></br>
        </Container>
        <BackgroundSection
          height={40}
          imageData={data.laszczka.childImageSharp.fluid}
        />
        <BackgroundSectionMobile
          height={40}
          imageData={data.laszczka.childImageSharp.fluid}
        />
        <Container style={{ marginTop: 30 }}>
          <Anchor id="nagrania-video" />
          <Header2>NAJPOPULARNIEJSZE MATERIAŁY VIDEO</Header2>
          <TopVideoMonuments />
          <ButtonLink center linkTo="/nagrania-video/">
            Zobacz więcej nagrań
          </ButtonLink>
          <br></br>
        </Container>
        {/* <Container style={{ marginTop: 30 }}>
          <Header1>Wirtualna Ziemia Mińska</Header1>
          <p>
            „Wirtualna Ziemia Mińska” jest projektem Fundacji Aktywny Mińsk
            Mazowiecki realizowanym we współpracy z Towarzystwem Przyjaciół
            Mińska Mazowieckiego dzięki dofinansowaniu Narodowego Centrum
            Kultury w ramach programu „Kultura w Sieci”. Stworzyliśmy ten
            portal, by zachęcić do zgłębiania tajników elementów kulturowego i
            historycznego dziedzictwa ziemi mińskiej. Przygotowując się do
            realizacji zadania postawiliśmy sobie za cel wykonanie narzędzia,
            które pozwoli dzięki zrozumiałej i atrakcyjnej formie przekazu
            zapewnić szeroki dostęp do efektów naszej pracy i umożliwi odbiorcom
            zgłębianie lokalnego bogactwa kulturowego. Dlatego w realizację
            zadania zaangażowaliśmy osoby cechujące się wysokim profesjonalizmem
            i doświadczeniem w prowadzeniu przedsięwzięć kulturalnych i
            popularyzujących np. zbiory muzealne. Podstawową misją, jaka
            towarzyszy nam przy realizacji tego zadania jest upowszechnianie i
            promowanie dziedzictwa kulturowego ziemi mińskiej oraz stwarzanie
            warunków do kształtowania postaw wspierających propagowanie tego
            dziedzictwa przez odbiorców. Naszym celem jest, by zrealizowane
            przedsięwzięcie było projektem trwałym, dostępnym dla odbiorców, a
            miarę możliwości, rozwijanym także w przyszłych latach. Dzięki
            realizacji zadania powstanie strona internetowa będąca doskonałą,
            nieistniejącą dotychczas bazą ukazującą dziedzictwo kulturowe ziemi
            mińskiej. Stworzyliśmy w ten sposób podwaliny pod wirtualne centrum
            historii, kultury i sztuki powiatu mińskiego.
          </p>
        </Container>
        <Container style={{ marginTop: 30, marginBottom: 30 }}>
          <Header2>
            Podziękowania dla Towarzystwa Przyjaciół Mińska Mazowieckiego
          </Header2>
          <p>
            Zarząd Fundacji Aktywny Mińsk Mazowiecki bardzo serdecznie dziękuje
            za zaangażowanie jakie ze strony Towarzystwa Przyjaciół Mińska
            Mazowieckiego zostało włożone w realizację zadania "Wirtualna Ziemia
            Mińska". Szczególne podziękowania składamy na ręce pana prezesa
            Marka Łodygi, Tomasza Adamczaka i Piotra Nowickiego. Cieszymy się,
            że mogliśmy z Wami współpracować przy tym projekcie i mamy wielką
            nadzieję na dalszy jego rozwój.
          </p>
        </Container> */}
        <LogoContainer>
          {/* <a href="#" title="Fundacja Aktywny Mińsk Mazowiecki">
            <Famm className="logo" />
          </a> */}
          <a href="#" title="Program Wsparcia Edukacji">
            <Img className="logo" fixed={data.pse.childImageSharp.fixed} />
          </a>
          <a href="#" title="Ministerstwo Nauki i Szkolnictwa Wyższego">
            <Img className="logo" fixed={data.mein.childImageSharp.fixed} />
          </a>
          <a href="#" title="Mazowiecki Uniwersytet Ludowy">
            <Img className="logo" fixed={data.mul.childImageSharp.fixed} />
          </a>
        </LogoContainer>
      </main>
    </>
  )
}

export const query = graphql`
  {
    laszczka: file(
      relativePath: { regex: "/dobre/muzeum-laszczki/" }
      name: { regex: "/okladka/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    blot: file(
      relativePath: { regex: "/minsk-mazowiecki/23blot/" }
      name: { regex: "/okladka/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kosciol: file(
      relativePath: { regex: "/universal/" }
      name: { regex: "/nowa-paralaksa/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trzeciaparalaksa: file(
      relativePath: { regex: "/universal/" }
      name: { regex: "/trzeciaparalaksa/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    seoimg: file(
      relativePath: { regex: "/universal/" }
      name: { regex: "/seoimagenew/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          src
        }
      }
    }
    tpmm: file(name: { regex: "/logoTPMM2/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    pse: file(name: { regex: "/mein_program_wsparcia_edukacji_logo_blue/" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mein: file(name: { regex: "/Logo_Ministerstwa_Edukacji_i_Nauki/" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mul: file(name: { regex: "/mul/" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default IndexPage
